<template lang="pug">
div
  .page-header
    .row
      nav-pills
      .col-md-5
        h1(v-if='clientes.nome') {{clientes.nome.toUpperCase()}}
      .col-md-7
  vue-element-loading(:active='isActive', spinner='bar-fade-scale', color='#428BCA')
  form(@submit.prevent='gravarCliente')
    .div
      .form-horizontal
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='nome')  Nome
          .col-sm-9
            input#clienteNome.col-xs-10.col-sm-8(type='text', v-model='clientes.nome', placeholder='Nome', required='')
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='telefone1')  Telefone 1
          .col-sm-9
            the-mask.col-xs-10.col-sm-3.telefone(type='text', :mask="['(##) ####-####', '(##) #####-####']", v-model='clientes.telefone1', placeholder='Telefone', required='', maxlength='15')
        .form-group
          select-form(v-model='clientes.mailling_id', name='Mailling', url='/mailling', id='id', titulo='nome', layout='true', required, request="true")
        .form-group
          select-form(v-model='clientes.especie_id', name='Especie', url='/especie', id='id', titulo='nome', layout='true', request="true")
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='beneficio')  Beneficio
          .col-sm-9
            input.col-xs-10.col-sm-3(type='text', v-model='clientes.beneficio', placeholder='Beneficio')
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='pessoa_tipo')
          .col-sm-9
            .radio
              label
                input.ace(name='pessoa_tipo', type='radio', value='7', v-model='clientes.pessoa_tipo', required='')
                span.lbl  Pessoa Física
            .radio
              label
                input.ace(name='pessoa_tipo', type='radio', value='8', v-model='clientes.pessoa_tipo', required='')
                span.lbl  Pessoa Jurídica
        div(v-if='clientes.pessoa_tipo == 7')
          .form-group
            label.col-sm-3.control-label.no-padding-right(for='cpf_cnpj')  CPF
            .col-sm-9
              the-mask.col-xs-10.col-sm-3.cpf(type='text', :mask="['###.###.###-##']", v-model='clientes.cpf_cnpj', placeholder='CPF', maxlength='14')
              .col-md-2
              .col-md-10
                small  {{ vldCPF }}
          .form-group
            label.col-sm-3.control-label.no-padding-right(for='rg')  RG
            .col-sm-9
              input.col-xs-10.col-sm-3(type='text', v-model='clientes.rg', placeholder='RG')
          .form-group
            label.col-sm-3.control-label.no-padding-right(for='nascimento')  Nascimento
            .col-sm-9
              the-mask.col-xs-10.col-sm-3.cpf(type='text', :mask="['##/##/####']", v-model='clientes.nasc_fund', placeholder='Nascimento', maxlength='10')
              //flat-pickr.col-xs-10.col-sm-3(
                v-model="clientes.nasc_fund",
                :config="config",
                class="form-control",
                placeholder="Selecione o Nascimento",
                name="date")
            .col-sm-6
              //the-mask.col-xs-10.col-sm-3.date-nasc(type='text', :mask="['##/##/####']", v-model='clientes.nasc_fund', placeholder='Data')
        div(v-else='')
          .form-group
            label.col-sm-3.control-label.no-padding-right(for='cpf_cnpj')  CNPJ
            .col-sm-9
              the-mask.col-xs-10.col-sm-4.cnpj(type='text', v-model='clientes.cpf_cnpj', :mask="['##.###.###/####-##']", placeholder='CNPJ', maxlength='18') div {{ vldCNPJ }}
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='telefone2')  Telefone 2
          .col-sm-9
            the-mask.col-xs-10.col-sm-3.telefone(type='text', v-model='clientes.telefone2', :mask="['(##) ####-####', '(##) #####-####']", placeholder='Telefone', maxlength='15')
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='telefone3')  Telefone 3
          .col-sm-9
            the-mask.col-xs-10.col-sm-3.telefone(type='text', v-model='clientes.telefone3', :mask="['(##) ####-####', '(##) #####-####']", placeholder='Telefone', maxlength='15')
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='email')  E-mail
          .col-sm-9
            input.col-xs-10.col-sm-5(type='email', v-model='clientes.email', placeholder='E-mail')
        .form-group
          label.col-sm-3.control-label.no-padding-right(for='obs')  Obs
          .col-sm-9
            textarea.form-control.autosize(v-model='clientes.obs', placeholder='Mensagem', v-autosize='clientes.obs')
        .widget-box
          .widget-header
            h4.widget-title Endereço
          vue-element-loading(:active='isActiveEndereco', spinner='bar-fade-scale', color='#428BCA')
          .widget-body
            .widget-main
              .form-group
                label.col-sm-3.control-label.no-padding-right(for='end_cep')  CEP
                .col-sm-2
                  the-mask.col-xs-10.col-sm-12.cep(type='text', placeholder='CEP', :mask="['#####-###']", v-model='clientes.end_cep', maxlength='9')
                .col-sm-4
                  a.btn.btn-default.btn-pesquisarcep(href='#', @click.prevent='pesquisarCep') Pesquisar
              .form-group
                label.col-sm-3.control-label.no-padding-right(for='end_rua')  Rua/Avenida/Tv
                .col-sm-9
                  input.col-xs-10.col-sm-8(type='text', placeholder='Rua/Avenida/Tv', v-model='clientes.end_rua')
              .form-group
                label.col-sm-3.control-label.no-padding-right(for='end_numero')  Nº
                .col-sm-9
                  input.col-xs-10.col-sm-1(type='text', placeholder='Nº', v-model='clientes.end_numero')
              .form-group
                label.col-sm-3.control-label.no-padding-right(for='end_compl')  Complemento/Casa/Apartamento
                .col-sm-9
                  input.col-xs-10.col-sm-8(type='text', placeholder='Complemento/Casa/Apartamento', v-model='clientes.end_compl')
              .form-group
                label.col-sm-3.control-label.no-padding-right(for='end_bairro')  Bairro
                .col-sm-9
                  input.col-xs-10.col-sm-6(type='text', placeholder='Bairro', v-model='clientes.end_bairro')
              .form-group
                label.col-sm-3.control-label.no-padding-right(for='end_estado')  Estado
                .col-sm-4
                  input.col-xs-2.col-sm-2(type='text', placeholder='Estado', v-model='clientes.end_estado')
              .form-group
                label.col-sm-3.control-label.no-padding-right(for='end_cidade')  Cidade
                .col-sm-4
                  input.col-xs-10.col-sm-6(type='text', placeholder='Cidade', v-model='clientes.end_cidade')
        .form-group(v-if='user.id_grupo == 1 || user.id_grupo == 3')
          select-form(request='true', v-model='clientes.usuario_id', name='Vendedor', url='/usuarios', id='id', titulo='name', layout='true', :required='true')
    .modal-footer
      div(v-if='$route.params.id')
        button.btn.btn-warning(type='submit')
          | Atualizar -&nbsp;
          i.fa.fa-address-book-o
          |  Cliente
        button.btn.btn-primary(type='submit', @click.prevent='gravarCliente("agenda")')
          | Atualizar -&nbsp;
          i.fa.fa-calendar-check-o
          |  Agenda
        button.btn.btn-success(type='submit', @click.prevent='gravarCliente("vendas")')
          | Atualizar -&nbsp;
          i.fa.fa-cart-plus
          |  Venda
      div(v-else)
        button.btn.btn-warning(type='submit')
          | Cadastrar -&nbsp;
          i.fa.fa-address-book-o
          |  Cliente
        button.btn.btn-primary(type='submit', @click.prevent='gravarCliente("agenda")')
          | Cadastrar -&nbsp;
          i.fa.fa-calendar-check-o
          |  Agenda
        button.btn.btn-success(type='submit', @click.prevent='gravarCliente("vendas")')
          | Cadastrar -&nbsp;
          i.fa.fa-cart-plus
          |  Venda

</template>

<script>
import Vue from 'vue'
import {mapGetters, mapActions} from 'vuex'
import axios from 'axios'
import {TheMask} from 'vue-the-mask'
import VueElementLoading from 'vue-element-loading'
import * as cpf from '@fnando/cpf'
import * as cnpj from '@fnando/cnpj'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import VueAutosize from 'vue-autosize'
import {Portuguese} from 'flatpickr/dist/l10n/pt.js'
import navPills from '../../components/NavPills'
import SelectForm from '@/components/form/SelectForm'
import req from '@/components/request'
import {erro} from '@/components/Message'

Vue.use(VueAutosize)

export default {
  components: {
    navPills,
    TheMask,
    flatPickr,
    VueElementLoading,
    SelectForm
  },
  watch: {
    'clientes.cpf_cnpj' () {
      if (this.clientes.pessoa_tipo) {
        this.changeVerificar('cpf')
      } else {
        this.changeVerificar('cnpj')
      }
    }
  },
  computed: {
    ...mapGetters([
      'user'
    ])
  },
  data () {
    return {
      isActive: false,
      isActiveEndereco: false,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altInput: true,
        altFormat: 'd F Y',
        dateFormat: 'Y-m-d',
        locale: Portuguese
      },
      clientes: {
        pessoa_tipo: 7,
        nome: null,
        cpf_cnpj: null,
        email: null,
        telefone1: null,
        telefone2: null,
        telefone3: null,
        nasc_fund: null,
        rg: null,
        end_rua: null,
        end_numero: null,
        end_compl: null,
        end_bairro: null,
        end_cep: null,
        end_cidade: null,
        end_estado: null,
        mailling_id: '',
        especie_id: '',
        beneficio: '',
        obs: '',
        usuario_id: ''
      },
      vldCPF: '',
      vldCNPJ: '',
      acesso: {}
    }
  },
  methods: {
    ...mapActions([
      'getLogsTotal'
    ]),
    gravarCliente (action) {
      let body = this.clientes
      body.usuario_id = !this.clientes.usuario_id ? this.user.id : this.clientes.usuario_id
      if (this.$route.params.id) {
        this.$axios.put(`/clientes/${this.$route.params.id}`, body).then(() => {
          this.getLogsTotal(this.user.id)
          switch (action) {
            case 'agenda':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}/agenda`)
              return
            case 'vendas':
              this.$router.push(`/sistema/clientes/${this.$route.params.id}/vendas`)
              return
            default:
              this.$router.push('/sistema/clientes')
          }
        })
      } else {
        this.$axios.post('/clientes', body).then(resp => {
          this.getLogsTotal(this.user.id)
          switch (action) {
            case 'agenda':
              this.$router.push(`/sistema/clientes/${resp.id}/agenda`)
              return
            case 'vendas':
              this.$router.push(`/sistema/clientes/${resp.id}/vendas`)
              return
            default:
              this.$router.push('/sistema/clientes')
          }
        })
      }
    },
    validaVerificar () {

    },
    verificar () {

    },
    getCliente () {
      req(`/clientes/${this.$route.params.id}`).then(resp => {
        delete resp.solicitante
        delete resp.usuario_nome
        delete resp.usuario_cad_id
        delete resp.usuario_cad_nome
        delete resp.datacad
        delete resp.datacad_atu
        delete resp.mae
        delete resp.mae_fantasia
        delete resp.id
        // Object.assign(this.clientes, resp)
        this.clientes = {...this.clientes, ...resp}
        this.isActive = false
        this.getEndereco()
      }).catch(response => {
        console.log('error: ' + response)
        erro()
      })
    },
    getEndereco () {
      req(`/clientes/${this.$route.params.id}/endereco`).then(resp => {
        delete resp.principal
        delete resp.cliente_id
        delete resp.usuario_id
        delete resp.id
        this.clientes = {...this.clientes, ...resp}
        this.isActiveEndereco = false
      }).catch(response => {
        console.log('error: ' + response)
        erro()
      })
    },
    pesquisarCep () {
      this.isActiveEndereco = true
      delete this.$axios.defaults.headers.common['Authorization']
      this.$axios.get(`//viacep.com.br/ws/${this.clientes.end_cep}/json/`).then(resp => {
        this.clientes.end_rua = resp.data.logradouro
        this.clientes.end_bairro = resp.data.bairro
        this.clientes.end_cidade = resp.data.localidade
        this.clientes.end_estado = resp.data.uf
        this.isActiveEndereco = false
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
      }).catch(() => {
        this.isActiveEndereco = false
      })
    },
    changeVerificar (tipo) {
      if (tipo === 'cpf') {
        if (!this.clientes.cpf_cnpj.length) {
          // eslint-disable-next-line no-return-assign
          return this.vldCPF = ''
        }
        if (this.clientes.cpf_cnpj && this.clientes.cpf_cnpj.length >= 1) {
          if (cpf.isValid(this.clientes.cpf_cnpj)) {
            this.vldCPF = ''
            this.getClienteId()
          } else {
            this.vldCPF = 'CPF inválido'
          }
        }
      } else if (tipo === 'cnpj') {
        if (!this.clientes.cpf_cnpj.length) {
          // eslint-disable-next-line no-return-assign
          return this.vldCNPJ = ''
        }

        if (this.clientes.cpf_cnpj && this.clientes.cpf_cnpj.length >= 1) {
          if (cnpj.isValid(this.clientes.cpf_cnpj)) {
            this.vldCNPJ = ''
            this.getClienteId()
          }
        } else {
          this.vldCNPJ = 'CNPJ inválido'
        }
      }
    },
    getClienteId () {
      if (!this.$route.params.id) {
        req(`/cpf/${this.clientes.cpf_cnpj}`).then(resp => {
          if (resp.length) {
            this.$router.push(`/sistema/clientes/${resp[0].id}`)
            this.isActive = true
            this.isActiveEndereco = true
            this.getCliente()
          }
        }).catch(response => {
          console.log('error: ' + response)
        })
      }
    }
  },
  created () {
    if (this.$route.params.id) {
      this.isActive = true
      this.isActiveEndereco = true
      this.getCliente()
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>
